import React from "react"
import { Box, Container, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import Caption from "./Caption"
import { PARTNER_LINK } from "../constants"

const BuiltBy = () => {
  return (
    <Box as="section" py="20" bg="bg.darker">
      <Container textAlign="center">
        <Caption variant="lighter">Built By</Caption>
        <Box mt="4">
          <a href={PARTNER_LINK} aria-label="Northern Labs official site">
            <StaticImage
              src="../images/northern-labs.svg"
              alt="Northern Labs"
              height={40}
            />
          </a>
        </Box>
      </Container>
    </Box>
  )
}

export default BuiltBy
