import React, { FC } from "react"
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react"
import Caption from "./Caption"
import Feature from "../interfaces/Feature"

interface FeatureListSectionProps {
  caption: string
  title: string
  items: Feature[]
}

/**
 * Section to show a list of features
 */
const FeatureListSection: FC<FeatureListSectionProps> = ({
  caption,
  title,
  items,
}) => {
  return (
    <Box as="section" py="32" bg="bg.main">
      <Container textAlign="center">
        <Caption>{caption}</Caption>
        <Heading my="4" color="font.dark">
          {title}
        </Heading>
        <Grid
          mt="16"
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          gap="8"
        >
          {items.map(item => (
            <GridItem key={item.name}>
              <Icon as={item.icon} boxSize={16} mb="4" color="bg.dark" />
              <Heading fontSize="xl" color="font.dark" my="4">
                {item.name}
              </Heading>
              <Text fontSize="md" color="font.main" my="2">
                {item.description}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default FeatureListSection
