import { Box } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useTranslation } from "react-i18next"
import SyntaxHighlighter from "react-syntax-highlighter"
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs"

import FeatureFocusSection from "../components/FeatureFocusSection"
import FeatureListSection from "../components/FeatureListSection"
import FeatureSection from "../components/FeatureSection"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import QuickStartSection from "../components/QuickStartSection"
import SEO from "../components/SEO"
import { FaCode, FaWallet } from "react-icons/fa"
import { GrCompliance } from "react-icons/gr"
import BuiltBy from "../components/BuiltBy"
import useWaitlistPopup from "../hooks/useWaitlistPopup"

const codeSnippet = `function App() {
  // ...
  return (
    <MintCartProvider>
      <MintCartCheckout
        clientSecret="pk_M9jL2iSXEQ307qkjrpzAfUxe"
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </MintCartProvider>
  );
}
`

const IndexPage = () => {
  const { openWaitlistPopup } = useWaitlistPopup()
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("title", { ns: "common" }) as string} />
      <Hero
        title={t("title")}
        description={t("description")}
        actionButtonLabel={t("action_button")}
      />
      <FeatureListSection
        caption={t("feature_list.caption")}
        title={t("feature_list.title")}
        items={[
          {
            name: t("feature_list.developer_feature.name"),
            icon: FaCode,
            description: t("feature_list.developer_feature.description"),
          },
          {
            name: t("feature_list.seller_feature.name"),
            icon: GrCompliance,
            description: t("feature_list.seller_feature.description"),
          },
          {
            name: t("feature_list.buyer_feature.name"),
            icon: FaWallet,
            description: t("feature_list.buyer_feature.description"),
          },
        ]}
      />
      <FeatureSection
        caption={t("feature.caption")}
        title={t("feature.title")}
        description={t("feature.description")}
        actionButtonLabel={t("feature.action_button")}
        onClick={openWaitlistPopup}
      >
        <Box p="4" bg="#282a36" rounded="md" overflow="auto">
          <SyntaxHighlighter
            language="typescript"
            showLineNumbers
            style={dracula}
          >
            {codeSnippet}
          </SyntaxHighlighter>
        </Box>
      </FeatureSection>
      <FeatureFocusSection
        caption={t("core_feature.caption")}
        title={t("core_feature.title")}
        description={t("core_feature.description")}
      >
        <StaticImage src="../images/smart_contracts.png" alt="feature" />
      </FeatureFocusSection>
      <QuickStartSection
        caption={t("quick_start.caption")}
        title={t("quick_start.title")}
        description={t("quick_start.description")}
        actionButtonLabel={t("quick_start.action_button")}
      />
      <BuiltBy />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
