import { ChevronRightIcon } from "@chakra-ui/icons"
import { Button } from "@chakra-ui/react"
import React, { FC } from "react"
import useWaitlistPopup from "../hooks/useWaitlistPopup"

interface CallToActionButtonProps {
  label: string
}

const CallToActionButton: FC<CallToActionButtonProps> = ({ label }) => {
  const { openWaitlistPopup } = useWaitlistPopup()
  const handleClick = () => openWaitlistPopup()

  return (
    <Button
      variant="primary"
      rightIcon={<ChevronRightIcon />}
      onClick={handleClick}
    >
      {label}
    </Button>
  )
}

export default CallToActionButton
