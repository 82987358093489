import React, { FC } from "react"
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import { ChevronRightIcon } from "@chakra-ui/icons"
import CallToActionButton from "./CallToActionButton"

interface HeroProps {
  title: string
  description: string
  actionButtonLabel: string
}

const Hero: FC<HeroProps> = ({ title, description, actionButtonLabel }) => {
  const [isSmallScreen] = useMediaQuery("(max-width: 880px)")

  return (
    <Box as="section">
      <Container>
        <Flex
          py={isSmallScreen ? "4" : "24"}
          flexWrap="wrap"
          justifyContent="center"
        >
          <Box
            alignItems="center"
            justifyContent="center"
            w={isSmallScreen ? "100%" : "50%"}
            minW="300px"
            my="28"
          >
            <Heading size={isSmallScreen ? "2xl" : "3xl"}>{title}</Heading>
            <Text my="8" color="font.main">
              {description}
            </Text>
            <CallToActionButton label={actionButtonLabel} />
          </Box>
          {!isSmallScreen && (
            <Box w={isSmallScreen ? "100%" : "50%"}>
              <Box mx="auto">
                <StaticImage
                  src="../images/hero.png"
                  style={{
                    maxHeight: "500px",
                  }}
                  imgStyle={{
                    height: "100%",
                    width: "auto",
                    margin: "auto",
                  }}
                  objectFit="cover"
                  loading="eager"
                  alt={title}
                />
              </Box>
            </Box>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default Hero
