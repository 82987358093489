import React, { FC, ReactNode } from "react"
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import Caption from "./Caption"
import { ChevronRightIcon } from "@chakra-ui/icons"

interface FeatureSectionProps {
  caption: string
  title: string
  description: string
  actionButtonLabel: string
  children?: ReactNode
  onClick?: () => void
}

/**
 * Section to show case feature
 */
const FeatureSection: FC<FeatureSectionProps> = ({
  caption,
  title,
  description,
  children,
  actionButtonLabel,
  onClick,
}) => {
  const gridTemplateColumns = useBreakpointValue({
    base: "1fr",
    md: "1fr 1fr",
  })

  return (
    <Box as="section" py="32">
      <Container>
        <Grid gridTemplateColumns={gridTemplateColumns} gap="8">
          <GridItem>
            <Caption>{caption}</Caption>
            <Heading my="4">{title}</Heading>
            <Text my="8" color="font.dark">
              {description}
            </Text>
            <Button
              variant="primary"
              rightIcon={<ChevronRightIcon />}
              onClick={onClick}
            >
              {actionButtonLabel}
            </Button>
          </GridItem>
          <GridItem overflow="hidden">{children}</GridItem>
        </Grid>
      </Container>
    </Box>
  )
}

export default FeatureSection
