import React, { FC, ReactNode } from "react"
import { Box, Container, Heading, Text } from "@chakra-ui/react"
import Caption from "./Caption"

interface FeatureFocusSectionProps {
  caption: string
  title: string
  description: string
  children?: ReactNode
}

/**
 * Section to show feature with immersive view & minimal text
 */
const FeatureFocusSection: FC<FeatureFocusSectionProps> = ({
  caption,
  title,
  description,
  children,
}) => {
  return (
    <Box as="section" py="32" bg="bg.dark">
      <Container textAlign="center">
        <Caption variant="dark">{caption}</Caption>
        <Heading my="4" color="white">
          {title}
        </Heading>
        <Text my="8" mx="auto" maxW="500px" color="font.light">
          {description}
        </Text>
        <Box maxW="800px" mt="16" mx="auto">
          {children}
        </Box>
      </Container>
    </Box>
  )
}

export default FeatureFocusSection
