import React, { ReactNode } from "react"
import { Text } from "@chakra-ui/react"
import { FC } from "react"

type TextVariant = "lighter" | "light" | "dark"

const getVariant = (variant: TextVariant): string => {
  switch (variant) {
    case "dark":
      return "secondary.main"
    case "light":
      return "primary.main"
    case "lighter":
      return "whiteAlpha.600"
    default:
      return "primary.main"
  }
}

interface CaptionProps {
  children: ReactNode
  variant?: TextVariant
}

const Caption: FC<CaptionProps> = ({ children, variant = "light" }) => {
  return (
    <Text as="span" fontWeight="medium" color={getVariant(variant)}>
      {children}
    </Text>
  )
}

export default Caption
