import React, { FC } from "react"
import { Box, Button, Container, Heading, Text } from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import Caption from "./Caption"
import CallToActionButton from "./CallToActionButton"

interface QuickStartSectionProps {
  caption: string
  title: string
  description: string
  actionButtonLabel: string
}

/**
 * Section for call-for-action
 */
const QuickStartSection: FC<QuickStartSectionProps> = ({
  caption,
  title,
  description,
  actionButtonLabel,
}) => {
  return (
    <Box as="section" py="32">
      <Container textAlign="center">
        <Caption>{caption}</Caption>
        <Heading my="4">{title}</Heading>
        <Text my="8" mx="auto" maxW="500px" color="font.main">
          {description}
        </Text>
        <CallToActionButton label={actionButtonLabel} />
      </Container>
    </Box>
  )
}

export default QuickStartSection
